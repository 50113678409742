body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.cursive {
  font-family: 'Dancing Script', cursive;
  font-size: 4vmin;
}
h2 {
  font-size: 6vmin !important;
  font-weight: 700;
  filter: drop-shadow(2px 2px 3px #000);
}
.pixel {
  font-size: 3vmin;
  font-family: 'Press Start 2P', cursive;
  color: #000;
  filter: drop-shadow(2px 2px 2px #fff);
}

a {
  text-decoration: none;
  transition: all 0.2s ease-in-out;
  &:hover {
    filter: drop-shadow(2px 2px 2px #000);
    img {
      filter: blur(0px) !important;
    }
  }
}
span {
  color: rgb(104, 40, 40);
}
svg {
  fill: #fff;
}
