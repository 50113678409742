@import './global.scss';
@import './anims.scss';
.home {
  .logo {
    img {
      z-index: 2;
      filter: drop-shadow(10px 10px 10px black);
    }
  }
  .bundle {
    display: flex;
    > * {
      width: 400px;
      height: 400px;
    }

    .skull {
      img {
        z-index: 2;
        height: 100%;
        filter: drop-shadow(10px 10px 10px rgb(141, 85, 0));
      }
    }
    .playlist {
      box-shadow: 10px 10px 10px black;
      transition: all 0.25s ease-in-out;
      iframe {
        z-index: 2;
        width: 100%;
        height: 100%;
      }
    }
  }
  @include mobile {
    .logo {
      width: 80vw;
      img {
        width: 80vw;
      }
    }
    .bundle {
      > * {
        width: 50vw;
        height: 30vh;
      }
      flex-direction: column;
      justify-content: center;
      .skull {
        img {
          height: 30vh;
        }
      }
    }
  }
  @include landscape {
    .bundle {
      > * {
        height: 30vh;
      }
    }
  }
}
