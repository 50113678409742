@import './global.scss';
@import './anims.scss';
.links {
  @include mobile {
    h2 {
      font-size: 8vmin !important;
    }
    ul {
      justify-content: center !important;
      li {
        a {
          span {
            font-size: 5vmin;
          }
        }
      }
    }
  }
  ul {
    width: 50vw;
    justify-content: space-between;
    display: flex;
    list-style: none;
    flex-direction: row;
    margin: 0;
    padding: 0;
    flex-wrap: wrap;
    > * {
      z-index: 2;
    }
    li {
      svg {
        height: 15vh;
        width: 15vh;
      }
      a {
        display: flex;
        flex-direction: column !important;
        align-items: center;
      }
    }
  }
}
