@import './global.scss';
@import './anims.scss';
.projects {
  width: 100%;
  justify-content: space-evenly !important;
  background-color: rgba($color: #3f0000, $alpha: 0.25);
  border-radius: 50px;
  @include mobile {
    .head {
      h2 {
        font-size: 8vmin !important;
      }
    }
    .lists {
      flex-wrap: wrap;
      ul {
        .titles {
          a {
            span {
              font-size: 5vmin;
            }
            img {
              height: 10vh !important;
              filter: blur(1px) !important;
            }
          }
        }
      }
    }
  }
  .lists {
    display: flex;
    width: 100vw;

    ul {
      display: flex;
      padding: 0;
      list-style: none;
      align-items: center;
      width: 50vw;
      justify-content: space-evenly;
      flex-direction: column;
      > * {
        z-index: 2;
      }

      .titles {
        a {
          cursor: pointer;
          display: flex;
          flex-direction: column;
          img {
            filter: blur(5px);
            height: 20vh;
            transition: all 0.2s ease-in-out;
          }
        }
      }
      .artists {
        display: flex;
        flex-direction: column;
      }
    }
  }
}
