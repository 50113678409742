.slide {
  transform: translateY(50px);
  opacity: 0;
  transition: transform 500ms ease-in;
  &.appear {
    transform: translateY(0px);
    opacity: 1;
  }
}

.fade {
  opacity: 0;
  transition: opacity 650ms ease-in;
  &.appear {
    opacity: 1;
  }
}
