@import './global.scss';
.app {
  &.dark {
    .bg {
      opacity: 80%;
    }
    .pepper {
      filter: drop-shadow(10px 10px 10px #fff);
    }
    .home {
      .bundle {
        .playlist {
          box-shadow: 10px 10px 10px #fff;
        }
      }
    }
    .projects {
      .head {
        h2 {
          color: #fff;
        }
      }
      .lists {
        ul {
          .titles {
            a {
              span {
                color: #fff;
                text-shadow: 2px 2px 5px rgb(194, 0, 0);
              }
            }
          }
        }
      }
    }
    .links {
      h2 {
        color: #fff;
      }
      ul {
        li {
          a {
            span {
              color: #fff;
              text-shadow: 2px 2px 5px rgb(194, 0, 0);
            }
          }
        }
      }
    }
  }
  text-align: center;
  height: 100vh;
  scroll-snap-type: y mandatory;
  scroll-behavior: smooth;
  overflow: scroll;
  &::-webkit-scrollbar {
    display: none;
  }
  > * {
    height: 100vh;
    scroll-snap-align: start;
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: column;
  }
  .bg {
    position: absolute;
    z-index: -1;
    width: 100vw;
    height: 100vh;
    overflow-x: hidden;
    overflow-y: hidden;
    opacity: 50%;
    filter: blur(8px);
    img {
      transition: all 0.3s ease-in-out;
      height: 100%;
      width: 100%;
      object-fit: cover;
      &::-webkit-scrollbar {
        display: none;
      }
    }
  }
  .pepper {
    filter: drop-shadow(10px 10px 10px black);

    position: absolute;
    width: 500px;
    height: 500px;
    transition: all 0.2s ease-in-out;
    img {
      transform: rotate(45deg);
      cursor: pointer;
      width: 100%;
      filter: contrast(50%);
      transition: all 0.3s ease-in-out;
      &:hover {
        filter: contrast(80%);
      }
    }
  }
}
@include landscape {
  .app {
    height: 100vh;
    .pepper {
      height: 100vh;
      img {
        height: 100vh;
      }
    }
  }
}
@include mobile {
  .app {
    width: 100vw;
    .pepper {
      width: 60vw;
    }
  }
}
