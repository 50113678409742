@import url('https://fonts.googleapis.com/css2?family=Dancing+Script:wght@400;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Press+Start+2P&display=swap');
@mixin mobile {
  @media (max-width: 640px) {
    @content;
  }
}
@mixin landscape {
  @media (max-height: 650px) {
    @content;
  }
}
